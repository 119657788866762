import React, { useState }     from 'react';
import { RangeCalendar }       from '@mantine/dates';
import { Popover, Stack }      from '@mantine/core';
import { disablePastDates }    from '@utils/date-utils';
import useIsMounted            from '@hooks/use-is-mounted';
import useDxbTheme             from '@hooks/use-dxb-theme';
import { SearchByRangeLabels } from '@features/search/components/RangeSearch/SearchByRangeLabels';
import useSearchData           from '@features/search/hooks/use-search-data';
import useStyles               from './SearchByRange.styles';


export const SearchByRange: React.FC = () => {

    const { classes } = useStyles();
    const isMounted = useIsMounted();

    const { setSearchRangeValue, getSearchRangeValue } = useSearchData();

    const { isSmallScreen } = useDxbTheme();

    const [isRangeVisible, setIsRangeVisible] = useState(false)

    return isMounted && (
        <Stack className={classes.stackMain}>

            <Popover position="bottom" opened={isRangeVisible} onChange={setIsRangeVisible}>

                <SearchByRangeLabels
                    _onClose={
                        () => {
                            setIsRangeVisible(!isRangeVisible);

                            //RESET
                            setSearchRangeValue([null, null]);
                        }
                    }
                />

                <Popover.Dropdown className={classes.popupDropdown}>
                    <RangeCalendar
                        // ref={ref}
                        value={getSearchRangeValue()}
                        onChange={(range) => {
                            setSearchRangeValue(range);

                            if (range[1] !== null) {
                                setIsRangeVisible(false);
                            }
                        }}
                        // sx={classes}
                        className={classes.rangeCal}
                        firstDayOfWeek="monday"
                        weekendDays={[0]}
                        allowLevelChange={false}
                        amountOfMonths={isSmallScreen ? 2 : 1}
                        excludeDate={(date) => {
                            return disablePastDates(date)
                        }}
                    />
                </Popover.Dropdown>
            </Popover>

        </Stack>
    )

}
