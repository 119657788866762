import { atom, useAtom }       from 'jotai';
import { empty, isRangeValid } from '@core/utils';
import { formatSearchDate }    from '@utils/date-utils';

//STATE
const searchRangeValueAtom = atom<[Date, Date]>([null, null]);
const selectedKeywordAtom = atom<string>('')
const selectedGuestCountAtom = atom<number>(0)

interface PropertySearchInput {
    keyword?: string;
    guestCount?: number;
    startDate?: string;
    endDate?: string;
}

export const searchPropertiesInputAtom = atom<PropertySearchInput>({
    keyword: null,
    guestCount: 0,
    startDate: null,
    endDate: null,
});


const useSearchData = () => {

    // STATE RANGE
    const [_searchRangeValue, _setSearchRangeValue] = useAtom(searchRangeValueAtom);
    const getSearchRangeValue = () => {
        return _searchRangeValue;
    }
    const setSearchRangeValue = (range) => {
        _setSearchRangeValue(range);
    }

    // STATE KEYWORD
    const [_selectedKeyword, _setSelectedKeyword] = useAtom(selectedKeywordAtom);
    const getSelectedKeyword = (): string => {
        return _selectedKeyword;
    }
    const setSelectedKeyword = (keyword: string) => {
        _setSelectedKeyword(keyword);
    }

    // STATE GUEST COUNT
    const [_selectedGuestCount, _setSelectedGuestCount] = useAtom(selectedGuestCountAtom);
    const getSelectedGuestCount = () => {
        return _selectedGuestCount;
    }
    const setSelectedGuestCount = (count) => {
        _setSelectedGuestCount(count);
    }

    // SEARCH BAR COMPLETE STATE
    const [_searchBarInput, _setSearchBarInput] = useAtom(searchPropertiesInputAtom);
    const getSearchBarInput = () => {
        return _searchBarInput;
    }
    const setSearchBarInput = (input) => {
        _setSearchBarInput(input);
    }

    function isReadyToSearch(): boolean {

        if (
            !empty(getSelectedKeyword()) ||
            isRangeValid(getSearchRangeValue()) ||
            getSelectedGuestCount() !== 0
        ) {

            setSearchBarInput({
                keyword: getSelectedKeyword(),
                guestCount: getSelectedGuestCount(),
                startDate: formatSearchDate(getSearchRangeValue()[0]),
                endDate: formatSearchDate(getSearchRangeValue()[1]),
            })

            /*console.log(
                extractTags(
                    getSelectedKeyword(),
                    getSelectedGuestCount(),
                    formatSearchDate(getSearchRangeValue()[0]),
                    formatSearchDate(getSearchRangeValue()[1])
                ), '')*/

            return true;

        } else {
            return false;
        }
    }

    return {
        getSearchRangeValue, setSearchRangeValue,
        getSelectedKeyword, setSelectedKeyword,
        getSelectedGuestCount, setSelectedGuestCount,
        getSearchBarInput, setSearchBarInput,
        isReadyToSearch,
    }

}

export default useSearchData;
