import React, { useEffect, useState } from 'react';
import { useClickOutside } from '@mantine/hooks';
import { Button, Group, Popover, Stack, Text, UnstyledButton } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons';
import { formatGuestsCount } from '@utils/dxb-utils';
import { CIconButton } from '@core/components/common/CIconButton';
import useIsMounted from '@hooks/use-is-mounted';
import useSearchData from '@features/search/hooks/use-search-data';
import { useGetMaxGuestCountQuery } from '../../../../generated/graphql';
import useStyles from './SearchByGuestCount.styles';


export const SearchByGuestCount: React.FC = () => {

    const { classes } = useStyles();
    const isMounted = useIsMounted();

    const { setSelectedGuestCount } = useSearchData();

    // COUNT LIMIT

    const { data } = useGetMaxGuestCountQuery();
    const [guestsLimit, setGuestsLimit] = useState<number>(0);

    const [count, setCount] = useState<number>(0)
    const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(false)

    const ref = useClickOutside(() => {
        setIsSelectorVisible(false)
        setSelectedGuestCount(count);
    });

    useEffect(() => {

        if (data?.getMaxGuestCount) {
            setGuestsLimit(data?.getMaxGuestCount.max);
        }

    }, [data])

    useEffect(() => {

        setSelectedGuestCount(count);

    }, [count]);

    function onPlus() {
        if (count >= guestsLimit) return;
        setCount(c => c + 1);
    }

    function onMinus() {

        const currentCount = count - 1;
        if (currentCount >= 0) {
            setCount(currentCount);
        } else {
            setCount(0);
        }
    }

    const Counter = (

        <Stack ref={ref}>
            <Group className={classes.guestGroup}>
                <CIconButton
                    onClick={() => onMinus()}
                    light={<IconMinus className={classes.guestIcon} size={20} stroke={1}/>}
                    dark={<IconMinus className={classes.guestIcon} size={20} stroke={1}/>}
                    props={{
                        variant: 'outline',
                        radius: 'xs'
                    }}
                />

                <Text className={classes.guestText} size={14} align="center">
                    {count}
                </Text>

                <CIconButton
                    onClick={() => onPlus()}
                    light={<IconPlus className={classes.guestIcon} size={20} stroke={1}/>}
                    dark={<IconPlus className={classes.guestIcon} size={20} stroke={1}/>}
                    props={{
                        variant: 'outline',
                        radius: 'xs'
                    }}
                />
            </Group>
            <Button className={classes.clearButton} radius="xs" disabled={count === 0} fullWidth={true} onClick={() => setCount(0)}>
                Clear
            </Button>
        </Stack>
    )


    return isMounted && (

        <Stack className={classes.stackMain}>

            <Popover opened={isSelectorVisible} onChange={setIsSelectorVisible}>

                <Popover.Target popupType="dialog">
                    <Group className={classes.guestWrapper}>

                        <UnstyledButton className={classes.guestMain} onClick={() => {
                            setIsSelectorVisible(!isSelectorVisible);
                            // setCount(0);
                        }}>
                            <Stack>
                                <Text className={classes.guestLabel}>Guests</Text>
                                <Text className={classes.guestDescription}>Traveling with others?</Text>
                                <Text className={classes.guestInput}>{formatGuestsCount(count, 'Add Guests')}</Text>
                            </Stack>
                        </UnstyledButton>

                    </Group>
                </Popover.Target>

                <Popover.Dropdown>
                    {
                        isSelectorVisible && Counter
                    }
                </Popover.Dropdown>
            </Popover>
        </Stack>

    )

}
