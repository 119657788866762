import React                from 'react';
import type { ButtonProps } from '@mantine/core';
import { Button }           from '@mantine/core';
import { NextLink }         from '@mantine/next';


interface Props {
    title: string;
    route?: string;
    props?: ButtonProps;
    className?: string;
}

export const CButton: React.FC<Props> = ({ title, route, props, className }) => {

    return (

        <Button title={title} className={className} component={NextLink} href={route} {...props}>
            {title}
        </Button>

    );

};
