import React, { ReactNode }                          from 'react';
import type { ActionIconProps }                      from '@mantine/core';
import { ActionIcon, MantineTheme, useMantineTheme } from '@mantine/core';


interface Props {
    onClick?: () => void;
    label?:string;
    dark?: ReactNode;
    light: ReactNode;
    props?: ActionIconProps;
    className?: string;
}

export const CIconButton: React.FC<Props> = ({
                                                 className,
                                                 onClick,
                                                 label,
                                                 dark,
                                                 light,
                                                 props,
                                             }) => {

    const { colorScheme }: MantineTheme = useMantineTheme();

    return (

        <ActionIcon className={className} onClick={onClick} {...props}>
            {colorScheme === 'dark' ? dark : light}
            {label && label}
        </ActionIcon>

    );

};
