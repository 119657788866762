import React, { useEffect, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import { getAddressText, mapFavParamToProps } from '@utils/dxb-utils';
import { PropertyItemCard } from '@features/common/cards/PropertyItem/PropertyItemCard';
import useUserData from '@features/common/hooks/use-user-data';
import { S3_PROPERTY_BUCKET_URL } from '@utils/network-utils';
import { FeaturedPropertyList } from '../../../../generated/graphql';


interface Props {
    data?: FeaturedPropertyList[];
}

export const FeaturedScroller: React.FC<Props> = ({ data }) => {

    const [localData, setLocalData] = useState(data);
    const { getUserId, getUserPropIds } = useUserData();

    useEffect(() => {

        if (getUserPropIds()) {
            const updatedData = mapFavParamToProps(getUserPropIds(), data)
            setLocalData(updatedData);
        }

    }, [getUserPropIds()]);

    const slides = localData?.map((slide) => {

        const thumbUrl = slide?.images?.filter((img) => img.type === 'thumb' && img)[0]?.src;

        return (
            <Carousel.Slide key={slide.id}>
                <PropertyItemCard
                    propertyId={slide.id}
                    name={slide.name}
                    thumb={thumbUrl && `${S3_PROPERTY_BUCKET_URL}/${thumbUrl}`}
                    address={getAddressText(slide.location)}
                    amount={slide.priceDetails.pricePerNight}
                    currency="AED"
                    details={slide.details}
                    //Don't Show featured tag as it is already in featured list
                    isFeatured={false}
                    settings={slide.settings}
                    isFavVisible={getUserId() !== -1}
                    // @ts-expect-error
                    isFav={slide.isFavProp}
                />
            </Carousel.Slide>
        );
    });

    return (
        <Carousel
            mb="xl"
            mx="auto"
            slideSize="70%"
            slideGap="sm"
            breakpoints={[
                { maxWidth: 'xl', slideSize: '33.30%' },
                { maxWidth: 'md', slideSize: '50%' },
                { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
            ]}
            slidesToScroll={1}
            loop={true}
            controlsOffset="sm"
            align="start"
            sx={(theme: { other }) => {
                return {
                    maxWidth: theme.other.maxWidth,
                    '.mantine-Carousel-controls': {
                        top: 'calc(35% - 13px)'
                    },
                }
            }}
            draggable={true}
            withIndicators={false}
            withControls={true}
            styles={{
                control: {
                    '&[data-inactive]': {
                        opacity: 0,
                        cursor: 'default',
                    },
                },
            }}
            previousControlLabel="Previous Property"
            nextControlLabel="Next Property"
        >

            {slides}

        </Carousel>
    );

};
