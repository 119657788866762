import React from 'react';
import { Carousel } from '@mantine/carousel';
import { CarouselSlide } from '@features/home/components/Carousal/Slide/CarouselSlide';
import { CarousalSlides } from '../../../../generated/graphql';
import { S3_SITE_MEDIA_BUCKET_URL } from '@utils/network-utils';


interface Props {
    slidesData: CarousalSlides[];
}

export const HomeCarousel: React.FC<Props> = ({ slidesData }) => {

    // TODO: GET THE PLACE HOLDER IMAGE - SPECIFIC TO THIS
    const emptySlide = <CarouselSlide
        backgroundImage="https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
        title="NO TITLE"
        category="NO HEADING"
    />;

    const dataSlides = slidesData.map((data) => {

        return <CarouselSlide
            key={data.title}
            backgroundImage={`${S3_SITE_MEDIA_BUCKET_URL}/${data.background_image_url}`}
            title={data.title}
            category={data.heading}
        />;

    });

    const slides = dataSlides.length !== 0 ? dataSlides : emptySlide;

    return (
        <Carousel
            mb="xl"
            mx="auto"
            slideSize="70%"
            slideGap="sm"
            breakpoints={[
                { maxWidth: 'xl', slideSize: '33.30%' },
                { maxWidth: 'md', slideSize: '50%' },
                { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
            ]}
            slidesToScroll={1}
            loop={true}
            controlsOffset="xl"
            align="start"
            sx={(theme: { other }) => {
                return {
                    maxWidth: theme.other.maxWidth,
                    '.mantine-Carousel-controls': {
                        top: 'calc(85% - 13px)'
                    },
                    '.mantine-Carousel-indicators': {
                        bottom: 34,
                    },
                }
            }}
            draggable={true}
            withIndicators={true}
            withControls={true}
            styles={{
                control: {
                    '&[data-inactive]': {
                        opacity: 0,
                        cursor: 'default',
                    },
                },
            }}
            previousControlLabel="Previous Benefit"
            nextControlLabel="Next Benefit"
        >

            {slides}

        </Carousel>
    );

};
