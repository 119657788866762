import { createStyles } from '@mantine/styles';


export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    colorScheme: any;
}) => ({

    stackMain: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    guestWrapper: {
        marginTop: 2,
        gap: 0,
    },

    guestMain: {
        position: 'relative',
        height: 'auto',
        paddingRight: theme.spacing.sm,
        borderRadius: theme.radius.xs,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    guestLabel: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.sm,
        paddingLeft: theme.spacing.sm,
        paddingTop: 10,
        fontWeight: 500,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
        zIndex: 1,
    },

    guestDescription: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: 28,
        lineHeight: 1.2,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        zIndex: 1,
    },

    guestInput: {
        height: 'auto',
        minWidth: 120,
        lineHeight: '34px',
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        paddingTop: 40,
        paddingBottom: 4,
        fontSize: 14,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
    },

    guestGroup: {
        width: 130,
        gap: 27,

        [theme.fn.smallerThan('sm')]: {

            '.mantine-Group-child': {
                width: 20,
                flexDirection: 'row',
            },
        },
    },

    guestText: {
        width: 20,
    },

    guestIcon: {
        color: theme.colors.orange[7],
    },

    clearButton: {
        fontWeight: 500,
        letterSpacing: 1,
        textTransform: 'uppercase',
    },

}));
