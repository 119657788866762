import React                                                 from 'react';
import { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import AppHead                                               from '@core/components/common/AppHead';
import AppLayout                                             from '@layouts/AppLayout';
import { HomeLanding }                                       from '@features/home/containers/HomeLanding';
import { apolloClient }                                      from '@lib/apollo';
import GET_CAROUSAL_SLIDES_QUERY                             from '@graphql/get-all-carousal-slides';
import GET_FEATURED_PROPERTIES_QUERY                         from '@graphql/get-featured-carousal-data';


const HomePage: NextPage = ({
                                siteCarousalData,
                                featuredScrollerData,
                                time
                            }: InferGetStaticPropsType<typeof getStaticProps>) => {

    return (
        <>
            {/*IMP FOR SIM*/}
            {/*{time}*/}
            <HomeLanding
                siteCarousalData={siteCarousalData}
                featuredScrollerData={featuredScrollerData}
            />
        </>
    );
};

// @ts-expect-error
HomePage.getLayout = function getLayout(page: typeof HomePage) {
    return (
        <>
            <AppHead
                title="Luxury Vacation Rentals"
                description="DXB Stay offers luxury vacation rental apartments in Dubai. Our rental properties offer exclusive views of landmarks and are located in key locations around Dubai."/>

            <AppLayout requireAuth={false}>
                {/*// @ts-expect-error*/}
                {page}
            </AppLayout>
        </>
    );
};

export const getStaticProps: GetStaticProps = async () => {

    let carousalDataPayload = null;
    let featuredDataPayload = null;

    // if (isProd) {

        const client = apolloClient(false);
        await client.resetStore();

        const { data: carousalData, errors: siteCarousalQueryError } = await client.query({
            query: GET_CAROUSAL_SLIDES_QUERY,
            fetchPolicy: 'network-only'
        });

        const { data: featuredData, errors: featuredCarousalError } = await client.query({
            query: GET_FEATURED_PROPERTIES_QUERY,
            fetchPolicy: 'network-only'
        });

        // console.log(carousalData.getAllCarousalSlides, 'IN HOME PAGE::carousalData');
        // console.log(featuredData.getFeaturedProperties, 'IN HOME PAGE::featuredData');

        // console.error(siteCarousalQueryError, 'IN HOME PAGE::getStaticProps');
        // console.error(featuredCarousalError, 'IN HOME PAGE::getStaticProps');

        carousalDataPayload = carousalData.getAllCarousalSlides
        featuredDataPayload = featuredData.getFeaturedProperties
    // }

    /*if (isDev) {
        carousalDataPayload = carousalSlideMock.data.getAllCarousalSlides
        featuredDataPayload = featuredPropsMock.data.getFeaturedProperties
    }*/

    return {
        props: {
            siteCarousalData: carousalDataPayload,
            featuredScrollerData: featuredDataPayload,
            time: new Date().toISOString()
        },
        revalidate: 1,
    };
};


export default HomePage;
