import { createStyles } from '@mantine/styles';


export const CONTAINER_HEIGHT = 500;
export const SM_CONTAINER_HEIGHT = 600;

export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    other: any;
    colorScheme: string;
    shadows: any;
}) => ({

    hero: {
        position: 'relative',
        backgroundImage: 'url(https://dxbstay-site-media.s3.amazonaws.com/dxbstay-banner.webp)',
        // backgroundImage: 'url(https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // maxWidth: 1200,
        margin: '0 auto',
        marginBottom: theme.spacing.xl,
        // borderRadius: theme.radius.xs,
    },

    container: {
        height: CONTAINER_HEIGHT,
        maxWidth: theme.other.maxWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing.xl * 3.5,
        zIndex: 10,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: SM_CONTAINER_HEIGHT,
            marginBottom: 0,
        },
    },

    paper: {
        margin: '0 auto',
        textAlign: 'justify',
        fontSize: 13,
        maxWidth: theme.other.maxWidth,
        padding: 10,
        boxShadow: theme.shadows.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7],
        marginBottom: theme.spacing.xl * -2,

        [theme.fn.smallerThan('sm')]: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: '100%',
            marginBottom: theme.spacing.xl,
        },
    },

    title: {
        color: theme.white,
        fontSize: 20,
        fontWeight: 200,
        lineHeight: 2,
        textAlign: 'center',
        paddingBottom: CONTAINER_HEIGHT / 12,
        textShadow: theme.shadows.sm,

        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    titleXS: {
        fontSize: 16,
        paddingBottom: 10,
        paddingTop: 10,
        margin: '0 auto',
        borderRadius: theme.radius.xs,
        textAlign: 'center',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '100%',
        color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[3],
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,

        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    /* description: {
        color: theme.white,
        maxWidth: 600,

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
            fontSize: theme.fontSizes.sm,
            textAlign: 'center',
        },
    },

    control: {
        marginTop: theme.spacing.xl * 1.5,
        fontWeight: 400,

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    }*/

}));
