import React                                from 'react';
import { Container, Overlay, Paper, Title } from '@mantine/core';
import { SearchBar }                        from '@features/search/components/bar/SearchBar';
import useStyles                            from './HeroBanner.styles';


export const HeroBanner: React.FC = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.hero}>

            <Overlay
                gradient="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .35) 80%)"
                opacity={1}
                zIndex={0}
                // radius="xs"
            />

            <Container className={classes.container}>
                <Title className={classes.title}>Top Locations | Spectacular Views | Unforgettable Experience</Title>
                <Title className={classes.titleXS}>Top Locations | Spectacular Views<br/>Unforgettable Experience</Title>
                <Paper className={classes.paper}>

                    <SearchBar/>

                </Paper>

                {/*<Text className={classes.description} size="sm" mt="xl">
                    Build fully functional accessible web applications faster than ever – Mantine includes
                    more than 120 customizable components and hooks to cover you in any situation
                </Text>

                <Button variant="gradient" size="sm" radius="xs" className={classes.control}>
                    View all our offers
                </Button>*/}
            </Container>
        </div>
    );

};
