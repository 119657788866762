import React, { forwardRef, useEffect, useState }                   from 'react';
import { Autocomplete, createStyles, Group, SelectItemProps, Text } from '@mantine/core';
import { extractKeywords }                                          from '@utils/dxb-utils';
import useIsMounted                                                 from '@hooks/use-is-mounted';
import useSearchData                                                from '@features/search/hooks/use-search-data';
import { Location, useGetSearchLocationsQuery }                     from '../../../generated/graphql';


const useStyles = createStyles((
    theme: {
        colors: { [x: string]: any[] };
        colorScheme: string;
        fn: any;
        fontSizes: any;
        spacing: any;
    }) => ({

    autocompleteInput: {
        position: 'relative',
        width: 190,
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },

        'input': {
            height: 'auto',
            paddingTop: 40,
            paddingBottom: 4,
            fontSize: 14,
        },

        '& .mantine-Autocomplete-icon': {
            top: 38,
            fontSize: 20,
        },

        'label': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.sm,
            paddingLeft: theme.spacing.sm,
            paddingTop: 20 / 2,
            zIndex: 1,
        },

        '& .mantine-Autocomplete-description': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.xs,
            paddingLeft: theme.spacing.sm,
            paddingTop: 28,
            zIndex: 1,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-Autocomplete-input::placeholder': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-Autocomplete-error': {
            fontSize: 12,
            paddingTop: 6,
        },

    },

}));

interface ItemProps extends SelectItemProps {
    keyword: string;
}

export const SearchByLocation: React.FC = () => {

    const { classes } = useStyles();
    const isMounted = useIsMounted();

    // LOCAL
    const [localValue, setLocalValue] = useState(null);
    const { setSelectedKeyword } = useSearchData();

    // SSR
    const { data } = useGetSearchLocationsQuery();
    const [searchLocations, setSearchLocations] = useState<Location[]>([]);
    const [keywords, setKeywords] = useState<string[]>([]);

    useEffect(() => {

        if (data?.getSearchLocations) {

            setSearchLocations(data?.getSearchLocations);
            setKeywords(extractKeywords(searchLocations))

        }

    }, [data, searchLocations])

    useEffect(() => {

        if (!localValue) {
            setSelectedKeyword(localValue)
        }

    }, [localValue]);

    const autoCompData = keywords.map((item) => ({ keyword: item, value: item }));

    const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ keyword, value, ...others }: ItemProps, ref) => (

            <div ref={ref} {...others}>
                <Group noWrap={true}>
                    <Text>{value}</Text>
                </Group>
            </div>
        )
    );


    return isMounted && (

        <Autocomplete
            className={classes.autocompleteInput}
            radius="xs"
            onItemSubmit={(item) => setSelectedKeyword(item?.value)}
            nothingFound="No Locations found"
            // limit={3}
            initiallyOpened={false}
            label="Your destination"
            description="Where would you like to go?"
            placeholder="example: Dubai Marina"
            itemComponent={AutoCompleteItem}
            // value={localValue}
            onChange={setLocalValue}
            data={autoCompData}
            filter={(value, item) => {
                return item.value.toLowerCase().includes(value.toLowerCase().trim())
            }}
        />

    )

}
