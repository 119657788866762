import React, { useEffect } from 'react';
import { Container, Group, Text, Title } from '@mantine/core';
import { AppRoutes } from '@core/router/AppRoutes';
import { CButton } from '@core/components/common/CButton';
import { HeroBanner } from '@features/home/components/Hero/HeroBanner';
import { HomeCarousel } from '@features/home/components/Carousal/HomeCarousel';
import { FeaturedScroller } from '@features/home/components/Scroller/FeaturedScroller';
import { CarousalSlides, FeaturedPropertyList } from '../../../generated/graphql';
import useStyles from './HomeLanding.styles';
import useAppData from '@hooks/use-app-data';


interface Props {
    siteCarousalData: CarousalSlides[];
    featuredScrollerData: FeaturedPropertyList[];
}

export const HomeLanding: React.FC<Props> = ({
                                                 featuredScrollerData,
                                                 siteCarousalData
                                             }) => {

    const { classes } = useStyles();

    const { setLastPage } = useAppData();

    useEffect(() => {

        setLastPage(AppRoutes.HOME)

    }, []);

    return (
        <div>
            {/* This div has been added to make HeroBanner full width */}
            {/* <SearchBar /> */}
            <HeroBanner/>

            <Container className={classes.homeLanding}>

                <section className={classes.homeInner}>

                    <Group>
                        <Title className={classes.h1} mb={20} order={1}>Discover what DXB Stay has to offer</Title>
                        <CButton
                            className={classes.viewButton}
                            title="View all offers"
                            route={AppRoutes.PROPERTIES}
                            props={{
                                variant: 'default',
                                mb: 20,
                            }}/>
                    </Group>

                    {featuredScrollerData.length !== 0
                        ? <FeaturedScroller data={featuredScrollerData}/>
                        : <Text align="center" color="dimmed" pb={20}>We are working on enhancing your experience.</Text>
                    }


                </section>

                <section className={classes.homeInner}>

                    <Title className={classes.h1} mb={20} order={1}>Benefits of booking with us</Title>

                    {siteCarousalData.length !== 0
                        ? <HomeCarousel slidesData={siteCarousalData}/>
                        : <Text align="center" color="dimmed" pb={20}>We are working on enhancing your experience.</Text>
                    }

                </section>

                {/*<section>

                <div>
                    <h1>Amenities and features...</h1>
                </div>

            </section>*/}


            </Container>
        </div>

    );

};
