import React from 'react';
import { Group, Popover, Stack, Text, UnstyledButton } from '@mantine/core';
import useIsMounted from '@hooks/use-is-mounted';
import { formatSearchBarDateLabel } from '@utils/date-utils';
import useSearchData from '@features/search/hooks/use-search-data';
import useStyles from './SearchByRange.styles';


interface Props {
    _onClose: () => void;
}

export const SearchByRangeLabels: React.FC<Props> = ({ _onClose }) => {

    const { classes } = useStyles();
    const isMounted = useIsMounted();
    const { getSearchRangeValue } = useSearchData();


    return isMounted && (
        <Popover.Target popupType="dialog">
            <Group className={classes.checkWrapper}>

                <UnstyledButton
                    className={classes.checkInMain}
                    onClick={() => {
                        _onClose();
                    }}>
                    <Stack>
                        <Text className={classes.checkLabel}>Check in</Text>
                        <Text className={classes.checkDescription}>When will your arrive?</Text>
                        <Text className={classes.checkInput}>{formatSearchBarDateLabel(getSearchRangeValue()[0], 'Select date')}</Text>
                    </Stack>
                </UnstyledButton>

                <UnstyledButton className={classes.checkOutMain}
                                onClick={() => {
                                    _onClose();
                                }}>
                    <Stack>
                        <Text className={classes.checkLabel}>Check out</Text>
                        <Text className={classes.checkDescription}>When do you plan to leave?</Text>
                        <Text className={classes.checkInput}>{formatSearchBarDateLabel(getSearchRangeValue()[1], 'Select date')}</Text>
                    </Stack>
                </UnstyledButton>

            </Group>
        </Popover.Target>
    )

}
