import { createStyles } from '@mantine/styles';


export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    colorScheme: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    shadows: any;
}) => ({

    rangeCal: {
        //This is the .mantine-RangeCalendar-calendarBase
        maxWidth: 'fit-content',
        width: 'fit-content',
        gap: 20,
        padding: 20,
        border: 1,
        borderStyle: 'solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        borderRadius: theme.radius.xs,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        // boxShadow: theme.shadows.sm,

        '.mantine-RangeCalendar-calendarHeader': {
            height: 60,
            borderBottom: 1,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderColor: theme.colors.orange[5],
            borderStyle: 'dashed',
            paddingTop: 5,
            paddingBottom: 5,
            color: theme.colors.dark[3],
        },

        '.mantine-RangeCalendar-calendarHeaderLevel': {
            color: theme.colors.dark[2],
            borderRadius: theme.radius.md,
            // position: 'absolute',
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 1,
            // marginLeft: 5,
            // marginRight: 5,
        },

        '.mantine-RangeCalendar-calendarHeaderControl': {
            borderRadius: theme.radius.md,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3],

            '&:hover': {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[4],
            },
        },


        '.mantine-RangeCalendar-weekday': {
            borderBottom: 1,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderColor: theme.colors.orange[5],
            borderStyle: 'dashed',
            paddingBottom: 10,
            fontSize: 12,
            letterSpacing: 1,
            textTransform: 'uppercase',

            [theme.fn.smallerThan('md')]: {
                fontSize: 12,
            },

        },

        /*'.mantine-RangeCalendar-weekdayCell': {
            paddingBottom: 10,
        },*/

        '.mantine-RangeCalendar-cell': {
            textAlign: 'center',
        },

        '.mantine-RangeCalendar-day': {
            margin: 2,
            borderRadius: theme.radius.md,
            // width: '100%',
            fontSize: 12,
            // width: 34,
            // height: 34,

            [theme.fn.smallerThan('md')]: {
                fontSize: 12,
                margin: 4,
            },

            /*[theme.fn.smallerThan('sm')]: {
                fontSize: 12,
                margin: 4,
            },*/

            '&[data-selected]': {
                backgroundColor: theme.colors.orange[4],
                borderRadius: theme.radius.md,
                position: 'relative',
            },

            '&[data-in-range]': {
                backgroundColor:
                    theme.colorScheme === 'dark'
                        ? theme.fn.rgba(theme.colors.orange[7], 0.2)
                        : theme.colors.orange[0],
                borderRadius: theme.radius.md,
            },

            '&[data-first-in-range]': {
                backgroundColor: theme.colors.orange[4],
                borderRadius: theme.radius.md,
                position: 'relative',

                '&::after': {
                    content: '""',
                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.fn.rgba(theme.colors.orange[7], 0.2)
                            : theme.colors.orange[0],
                    position: 'absolute',
                    right: 0,
                    left: 20,
                    top: 0,
                    bottom: 0,
                    zIndex: -1,
                },
            },

            '&[data-last-in-range]': {
                backgroundColor: theme.colors.orange[4],
                borderRadius: theme.radius.md,
                '&::after': {
                    content: '""',
                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.fn.rgba(theme.colors.orange[7], 0.2)
                            : theme.colors.orange[0],
                    position: 'absolute',
                    left: 0,
                    right: 20,
                    top: 0,
                    bottom: 0,
                    zIndex: -1,
                },
            },

            '&[data-weekend]': {
                // color: theme.colors.orange[5],
                color: theme.colors.orange[9],
            },

            '&[disabled]': {
                color: theme.colors.gray[5],
                textDecoration: 'line-through',
                textDecorationColor: theme.colors.orange[5],
            },
        },

        '.mantine-RangeCalendar-monthPickerControl': {
            margin: 2,
            flex: '0 0 20%',
        },

        '.mantine-RangeCalendar-monthPickerControlActive': {
            backgroundColor: theme.colors.orange[3],

            '&:hover': {
                backgroundColor: theme.colors.orange[5],
            },
        },
    },

    checkPopover: {
        padding: 0,
    },

    popupDropdown: {
        border: 'none',
        padding: 0,
        background: 'none',
        [theme.fn.smallerThan('sm')]: {
            top: '370px !important',
        },
    },

    stackMain: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    checkWrapper: {
        marginTop: 2,
        gap: 0,
    },

    checkInMain: {
        position: 'relative',
        height: 'auto',
        paddingRight: theme.spacing.sm,
        borderRadius: theme.radius.xs,


        [theme.fn.largerThan('sm')]: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
            borderRight: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
            borderBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

    },

    checkOutMain: {
        position: 'relative',
        height: 'auto',
        paddingRight: theme.spacing.sm,
        borderRadius: theme.radius.xs,


        [theme.fn.largerThan('sm')]: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        },

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        },
    },

    checkLabel: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.sm,
        paddingLeft: theme.spacing.sm,
        paddingTop: 10,
        fontWeight: 500,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
        zIndex: 1,
    },

    checkDescription: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: 28,
        lineHeight: 1.2,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        zIndex: 1,
    },

    checkInput: {
        height: 'auto',
        minWidth: 150,
        lineHeight: '34px',
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        paddingTop: 40,
        paddingBottom: 4,
        fontSize: 14,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
    },

}));
