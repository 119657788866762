import React                  from 'react';
import { Paper, Text, Title } from '@mantine/core';
import { Carousel }           from '@mantine/carousel';
import useStyles              from './CarouselSlide.styles';


interface CarouselSlideProps {
    backgroundImage: string;
    title: string;
    category: string;
}

export const CarouselSlide: React.FC<CarouselSlideProps> = ({
                                                                title,
                                                                category,
                                                                backgroundImage
                                                            }) => {

    const { classes } = useStyles();

    return (
        <Carousel.Slide>
            <Paper
                // shadow="md"
                // p="xl"
                radius="xs"
                sx={{ backgroundImage: `url(${backgroundImage})` }}
                className={classes.card}
            >
                <div className={classes.carouselText}>
                    <Text className={classes.category} size="xs">
                        {category}
                    </Text>
                    <Title order={1} className={classes.title}>
                        {title}
                    </Title>
                </div>
            </Paper>
        </Carousel.Slide>
    );

};
