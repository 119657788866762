import React, { useEffect }   from 'react';
import { useRouter }          from 'next/router';
import { Group }              from '@mantine/core';
import { IconSearch }         from '@tabler/icons';
import { ClientOnly }         from '@lib/ClientOnly';
import { AppRoutes }          from '@core/router/AppRoutes';
import { CIconButton }        from '@core/components/common/CIconButton';
import { SearchByLocation }   from '@features/search/components/SearchByLocation';
import { SearchByRange }      from '@features/search/components/RangeSearch/SearchByRange';
import { SearchByGuestCount } from '@features/search/components/GuestCount/SearchByGuestCount';
import useSearchData          from '@features/search/hooks/use-search-data';
import useIsMounted           from '@hooks/use-is-mounted';
import useStyles                           from './SearchBar.styles';
import useNotification, {NotificationType} from '@hooks/useNotification';


export const SearchBar: React.FC = () => {

    const { notify } = useNotification();

    const { classes } = useStyles();
    const isMounted = useIsMounted();
    const router = useRouter()

    const {
        isReadyToSearch,
        setSearchBarInput
    } = useSearchData();

    useEffect(() => {

        setSearchBarInput({
            keyword: null,
            guestCount: 0,
            startDate: null,
            endDate: null,
        })

    }, [])

    const onSubmitSearch = async () => {

        if (isReadyToSearch()) {
            await router.push(AppRoutes.SEARCH)
        } else {
            notify(
                NotificationType.INFO,
                `Please select your dates to search.`,
                4000
            );
        }

    }

    return isMounted && (

        <Group className={classes.searchGroup} spacing={10} align="center">

            <ClientOnly className={classes.searchLocationClient}>

                <SearchByLocation/>

            </ClientOnly>

            <SearchByRange/>

            <ClientOnly className={classes.searchLocationClient}>
                <SearchByGuestCount/>
            </ClientOnly>

            <CIconButton
                className={classes.searchGlass}
                label="Search"
                onClick={onSubmitSearch}
                light={<IconSearch color="orange" size={32} stroke={1}/>}
                dark={<IconSearch color="orange" size={32} stroke={1}/>}
            />

        </Group>

    )

}
