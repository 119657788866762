import { createStyles } from '@mantine/styles';


export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    colorScheme: any;
}) => ({

    searchLocationClient: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    searchGroup: {

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            paddingBottom: 16,
            paddingRight: 16,
            paddingLeft: 16,

            '.mantine-Group-child': {
                width: '100%',
            },
        },

    },

    searchGlass: {
        width: 70,
        borderRadius: theme.radius.xs,
        fontWeight: 400,
        fontSize: theme.fontSizes.sm,
        flexDirection: 'column',
        height: 'auto',
        gap: 10,
        marginTop: 2,
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.xs,
        paddingRight: theme.spacing.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

}));
