import { createStyles } from '@mantine/styles';


export default createStyles((theme: {
    colors: { [x: string]: any[] };
    other: any;
    colorScheme: any;
    fn: any;
}) => ({
    homeLanding: {
        margin: '0 auto',
        maxWidth: '100%',
        // paddingLeft: 20,
        // paddingRight: 20,
    },

    homeInner: {
        margin: '0 auto',
        maxWidth: theme.other.maxWidth,
    },

    viewButton: {
        fontWeight: 400,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[6],

        ':hover': {
            borderColor: theme.colors.orange[5],
        },

        [theme.fn.smallerThan('sm')]: {
            minWidth: '50%',
            margin: 'auto',
            marginBottom: 20,
            marginTop: -20,
        },
    },

    h1: {
        flex: 1,
        letterSpacing: 0.6,
        [theme.fn.smallerThan('sm')]: {
            minWidth: '100%',
            fontSize: 16,
            textAlign: 'center',
        },
    },

}));
