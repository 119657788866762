import { createStyles } from '@mantine/styles';


export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    shadows: any;
    colorScheme: string;
    black: any;
}) => ({

    card: {
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: 'none',
        border: 'none',
    },

    carouselText: {
        width: '100%',
        padding: theme.spacing.xl,
        borderRadius: theme.radius.xs,
        background: theme.fn.gradient({ from: theme.fn.rgba(theme.black, 0.6), to: theme.fn.rgba(theme.white, 0), deg: 180 })
    },

    title: {
        color: theme.white,
        lineHeight: 1.2,
        fontSize: 32,
        marginTop: theme.spacing.xs,
    },

    category: {
        color: theme.white,
        opacity: 0.7,
        fontSize: 12,
        textTransform: 'uppercase',
    }

}));
